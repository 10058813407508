import type {
  LanguageSwitchLinkFragment,
  RouteQuery,
} from '#graphql-operations'

export function setLanguageLinksFromFragment(
  links: LanguageSwitchLinkFragment[],
) {
  const route = useRoute()

  definePageLanguageLinks(
    route.path,
    links.reduce<Record<string, string>>((acc, v) => {
      if (v.language.id && v.url.path) {
        acc[v.language.id] = v.url.path
      }
      return acc
    }, {}),
  )
}

export function setLanguageLinksFromRoute(
  query?: RouteQuery | undefined | null,
) {
  if (query && query.route && 'languageSwitchLinks' in query.route) {
    setLanguageLinksFromFragment(query.route.languageSwitchLinks)
    return
  }
  const route = useRoute()
  definePageLanguageLinks(route.path, {})
}
